.sidebar_main {
    position: fixed;
}

.sidebar {
    top: 56px;
    bottom: 0;
    left: 0;
    -webkit-transition: width .05s linear;
    transition: width .05s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    z-index: 1000;
    width: 18%;
    float: left;
    border-right: 1px solid #eaeaea;
    background: #fff;
    height: calc(100vh - 78px);
    padding-top: 20px;
    overflow-y: auto;
    position: fixed;

    // &:hover,
    // &.expanded {
    //     width: 250px;
    //     overflow: visible;
    // }
    h6 {
        font-size: 15px;

    }

    >ul {
        margin: 7px 0;
        list-style-type: none;
        padding: 0;
        float: left;
        width: 100%;

        &.logout {
            position: absolute;
            left: 0;
            bottom: 0;
        }

        li {
            position: relative;
            width: 86%;
            padding: 5px 18px;
            border-left: 3px solid transparent;
            float: left;
            color: $gray-900;
            font-size: $font-size-base;
            font-weight: 500;
            line-height: 26px;

            >a {
                position: relative;
                display: table;
                border-collapse: collapse;
                border-spacing: 0;
                color: $gray-900;
                font-size: $font-size-base;
                text-decoration: none;
                -webkit-transform: translateZ(0) scale(1, 1);
                -webkit-transition: all .1s linear;
                transition: all .1s linear;
                font-weight: 400;
                line-height: 26px;

                &:hover {
                    color: #000;
                }

                i {
                    font-size: $font-size-sm * 1.5;
                    display: table-cell;
                    position: relative;
                    text-align: center;
                    padding: 13px 20px;
                }
            }

            &:hover {
                opacity: 0.8;
                cursor: pointer;
                background: #eaeaea;
            }

            &.activetab {
                background: #E9F2FF;
                border-left: 3px solid #0C66E4;
                color: #3366FF;
                font-weight: 600;

                a {
                    color: #3366FF;
                }
            }

            &.active {
                background: #E9F2FF;
                border-left: 3px solid #0C66E4;
                color: #3366FF;
                font-weight: 600;

                a {
                    color: #3366FF;
                    font-weight: 600;

                }
            }
        }
    }

    .nav-icon {
        position: relative;
        display: table-cell;
        width: 60px;
        height: 36px;
        text-align: center;
        vertical-align: middle;
        font-size: 18px;
    }

    .nav-text {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        width: 190px;
    }

}

@each $key, $val in $theme-colors {
    .sidebar-#{$key} {
        @extend .sidebar;
        background-color: $val;

        >ul {
            li {
                &:hover {
                    background-color: darken($val, 4%);
                }

                >a {
                    color: $gray-100;
                }
            }
        }

        &.text-white {
            >ul {
                li {

                    >a {
                        color: $gray-700;
                    }
                }
            }
        }
    }
}