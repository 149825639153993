$headerheight: 54px;

header {
    width: 100%;
    height: $headerheight;
    background-color: #ffffff;
    border-bottom: 1px solid #eaeaea;
    position: fixed;
    z-index: 3;
    top: 0px;
}

.logospace {
    width: 200px;
    float: left;
    height: $headerheight;
}

.logo {
    width: auto;
    height: 35px;
    margin: 10px;
    float: left;
    filter: grayscale(1);
}

nav {
    float: left;
    height: $headerheight;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            float: left;
            padding: 14px 20px;

            a {
                text-decoration: none;
                color: #777;
                font-size: 14px;
                font-weight: 400;
                border-bottom: 3.5px solid transparent;
                padding-bottom: 15.5px;

                &:hover {
                    color: #333;
                }

                &.active {

                    padding: 8px 10px;
                    border-radius: 6px;
                    font-weight: 500;
                }

                &.navactive {
                    color: #0C66E4;
                    font-weight: 600;
                    border-bottom: 3.5px solid #0C66E4;
                }
            }

            &.main-dropdown {
                background: none;

                //  padding: 10px;
                a {
                    padding: 2px 10px;
                }

                &:hover {
                    .dropdown_menu {
                        display: table;
                        background: #fff;
                        box-shadow: 0 0 10px 0 rgb(82 63 105 / 15%);

                    }

                    li {
                        display: block;
                        background: none;

                        &:hover {
                            background: #fafafa;
                        }
                    }
                }

                .dropdown_menu {
                    width: 200px !important;


                    li {
                        float: none;

                        div {
                            float: left;

                            &::before {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

//Animated Dropdown Scss

.menu-container {
    margin-top: 20px;
}

.menu-container h3 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
}

.menu-container:nth-child(3) {
    margin-top: 200px;
}

nav {
    width: 65%;
    display: flex;
    justify-content: start;
    margin-left: 40px;
}

.menu {
    display: flex;
    justify-content: right;
}

.dropdown {
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #202020;
    background: #fafafa;
    position: relative;
    font-size: 15px;
    perspective: 1000px;
    z-index: 100;
}

.dropdown:hover {
    background: #f3f3f3;
    cursor: pointer;
}

.dropdown:hover .dropdown_menu li {
    display: block;
}

.dropdown_menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 170px;
    perspective: 1000px;
    z-index: -1;
    height: 0;
    //  box-shadow: 0 30px 50px 0 rgb(82 63 105 / 15%);
}

.dropdown_menu li {
    display: none;
    color: #202020;
    background: #fafafa;
    padding: 10px;
    font-size: 14px;

}

.dropdown_menu li:hover {
    background-color: #f3f3f3;
}

.dropdown:hover .dropdown_menu {
    display: block;

    animation: heightanimate 5s ease-in-out forwards;
    // transition: height 50s ease-in-out;

}

.dropdown:hover .dropdown_menu--animated {
    display: block;

}

.dropdown_menu-2 .dropdown_item-1 {
    transform-origin: top bottom;
    animation: rotateX 50ms 30ms ease-in-out forwards;
}

.dropdown_menu-2 .dropdown_item-2 {
    transform-origin: top bottom;
    animation: rotateX 50ms 60ms ease-in-out forwards;
}

.dropdown_menu-2 .dropdown_item-3 {
    transform-origin: top bottom;
    animation: rotateX 50ms 90ms ease-in-out forwards;
}

.dropdown_menu-2 .dropdown_item-4 {
    transform-origin: top bottom;
    animation: rotateX 50ms 120ms ease-in-out forwards;
}

.dropdown_menu-2 .dropdown_item-5 {
    transform-origin: top bottom;
    animation: rotateX 50ms 150ms ease-in-out forwards;
}

@-moz-keyframes rotateX {
    0% {
        opacity: 0;
        transform: rotateX(-60deg);
    }

    50% {
        transform: rotateX(-10deg);
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

@-webkit-keyframes rotateX {
    0% {
        opacity: 0;
        transform: rotateX(-60deg);
    }

    50% {
        transform: rotateX(-10deg);
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

@-o-keyframes rotateX {
    0% {
        opacity: 0;
        transform: rotateX(-60deg);
    }

    50% {
        transform: rotateX(-10deg);
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

@keyframes rotateX {
    0% {
        opacity: 0;
        transform: rotateX(-60deg);
    }

    50% {
        transform: rotateX(-10deg);
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

.code-container-import {
    font-size: 13px;
    background: rgb(40, 42, 54);
    color: rgb(248, 248, 242);
    border-radius: 3px;
    line-height: 22px;
    overflow-x: auto;
    white-space: pre;
}

.notification-main {
    background: #F3F6F9;
    font-size: 14px;
    padding: 15px;
    color: #097489;
    border-radius: 6px;
    margin-bottom: 50px;
    display: flex;
    gap: 12px;
    border: 1px solid #E5EAF2;



    .notification-content {
        flex-direction: column;
        flex-grow: 1;


        a {
            margin-left: 5px;
            margin-right: 5px;
            font-weight: 500;
            color: #1460ba;
            font-size: 15px;
        }

    }
}

.section-border-bottom {
    border-top: 1px solid #eee;
    margin-top: 30px;
}


.content-header {
    background-color: #f5f5f5;
    position: relative;
    float: left;
    width: 100%;



    h1 {
        font-size: 26px;
        padding: 60px 0px 0px 60px;

    }

    p {
        font-size: 13px;
        padding: 0 0px 60px 60px;
    }

    &.content-header-1 {
        top: 55px;
        width: 94%;
        padding: 0px 0px 40px 6%;

    }

}