.body-content {
  width: calc(100vw - 18%);
  float: right;
  position: relative;
  top: 56px;
}

.content-area {
  padding: 40px 60px;
  // height: calc(100vh - 54px);
  // max-height: 100vh;
}

.content-area-fullwidth {
  width: 100%;
  float: left;
  padding: 40px;
  height: calc(100vh - 54px);
  max-height: 100vh;
}

.content-area,
.content-area-fullwidth {
  .heading,
  .main-heading {
    font-size: 24px;
    font-weight: 500;
  }

  p {
    font-size: 13px;
  }

  .sub-heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  p {
    font-size: 13px;
    margin-top: 4px;
    margin-bottom: 30px;
  }

  section {
    padding: 1rem 0 2rem;
  }
}

.content-wrapper .content-area {
  grid-area: content;

  section {
    width: 100%;
  }
}

.content-wrapper {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: minmax(25rem, 75rem) 1fr;
  grid-template-areas: "content nav";
  width: 100%;
  margin-left: 0;
}

.bookmark-navigator {
  width: 160px;
  float: left;
  padding: 20px;
  border-left: 1px solid #eee;
  height: 80vh;
  grid-area: nav;
  position: sticky;
  top: 56px;

  h6 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 600;
    color: #656d7a;
  }
}

.bookmark-navigator ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: sticky;
  inset-block-start: 80px;
}

.bookmark-navigator ul li a {
  font-size: 13px;
  color: #333;
  text-decoration: none;
  line-height: 32px;
  transition: all 0.3s ease-in-out;
}

.bookmark-navigator ul li.active:before {
  border-left: 2px solid #0c66e4;
  content: "";
  position: absolute;
  width: 2px;
  height: 30px;
  left: -21px;
  transition: all 0.3s ease-in-out;
}

.bookmark-navigator ul li.active a {
  color: #0c66e4;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.text > code {
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  padding: 2px 4px;
  color: #1c2025;
  background-color: #f3f6f9;
  border: 1px solid;
  border-color: #dae2ed;
  border-radius: 6px;
  font-size: 0.8125rem;
  direction: ltr;
  -webkit-box-decoration-break: clone;
}

html,
body {
  line-height: 24px;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
}

h2 {
  font-size: 1.5rem;
  font-weight: 500;
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;
}

h4 {
  font-size: 1.15rem;
  font-weight: 500;
}

h5 {
  font-size: 1.1rem;
  font-weight: 500;
}

h6 {
  font-size: 1rem;
  font-weight: 500;
}

.m-0 {
  margin: 0 !important;
}

.m-20 {
  margin: 20px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.content-area > .row:first-of-type {
  display: none;
}

.content-area > hr:first-of-type {
  display: none;
}

section h3:nth-of-type(1) {
  margin-top: -20px;
}

.foundations_main > .row:first-of-type {
  display: block;
  height: 200px;
}

foundations_main > hr:first-of-type {
  display: block;
}

.foundations_main {
  section h3:nth-of-type(1) {
    margin-top: 0px;
  }
}

.component_main > .row:first-of-type {
  display: flex;
}

//demo

.content-area > .row {
  margin-bottom: 40px;
  margin-top: 10px;
}

.body-content .icons-page {
  margin: -60px 0 0 -22%;
  float: left;
  width: 122%;
  overflow-x: hidden;
}

.landingpage {
  width: 100%;
  margin: 56px auto;

  * {
    font-family: "Lexend", sans-serif !important;
  }
}

section.hero-section-wrapper {
  background: #f5f9ff;
  padding: 160px 17% 0;
  float: left;
  height: calc(100vh - 216px);
}

.hero-content {
  width: 65%;
  float: left;
  margin-right: 4%;
}

.hero-banner {
  width: 30%;
  float: left;
}

.hero-banner img {
  width: 100%;
  height: auto;
}

.hero-content h1 {
  font-size: 40px;
  font-weight: 500;
}

.hero-content p {
  font-size: 18px;
  line-height: 32px;
  color: #545454;
}

.hero-content a {
  background: #2953e8;
  padding: 10px 20px;
  border-radius: 6px;
  color: #fff;
  text-decoration: none;
  margin-top: 10px;
  float: left;
}

.sidebar > ul li > a {
  display: block;
}

.typography {
  padding: 40px;
  background: #f6f6f6;
  margin-bottom: 40px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0px;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
  }

  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
  }

  h3 {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }

  h5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  h6 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

.space-img {
  width: 90%;
}

//Toasters

/**************/
h1 {
  font-weight: 600;
  font-size: 18px;
}

h2 {
  font-weight: 600;
  font-size: 16px;
}

p {
  font-size: 14px;
}

// section {
//     padding-top: $space-default;
//     padding-bottom: $space-default;
//     // border-bottom: 1px solid $black-100;

// }

.main-heading {
  margin-bottom: 16px;
  font-size: 24px;
  margin-top: 8px;
}

.sub-heading {
  margin-top: 32px;
  margin-bottom: 8px;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-10 {
  margin-top: 10px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

//breadcrumbs
.MuiBreadcrumbs-root li.MuiBreadcrumbs-li p {
  line-height: 24px;
  margin: 0;
}

.MuiBreadcrumbs-root li:last-child.MuiBreadcrumbs-li p {
  font-weight: 600;
  font-size: 14px;
}
// .MuiTypography-root{
//     margin-bottom: 8px !important;
// }

// showcase in design system
@import "../../../designsystem/scss/abstract/all";
$color-text-typography-form-view: $black-1000;
$color-text-typography: $black-900;
$color-text-typography-formview: $black-700;

$heading-level2-h1-typography: $heading-level2;
$heading-level2-h2-typography: $heading-level5;
$heading-level2-h3-typography: $heading-level3;

$display-level1-h2-typography: 28px;
$display-level2-h4-typography: $heading-level1;

$text-regular-typography: $text-regular;
$text-bold-typography: $text-bold;
$text-bolder-typography: $text-bolder;
$text-boldest-typography: $text-bolder-650;

$line-height-large-typography: $lineheight-large;
$line-height-extra-large-typography: 40px;
$line-height-typography-32: 32px;
$line-height-typography-28: 28px;
$line-height-base-typography: $lineheight-base;
$line-height-typography-20: 20px;
$line-height-small-typography: $lineheight-small;

$font-size-typography-small: $font-small;
$font-size-typography-base: $font-base;
$font-size-typography-medium: $font-base;
.freya-fusion {
  // max-width:fit-content;

  .MuiTypography-root {
    //  margin: $space-nospace;
    padding: $space-nospace;
  }

  //Heading Level//
  .MuiTypography-h1 {
    color: $color-text-typography;
    font-size: $heading-level2-h1-typography;
    font-weight: $text-boldest-typography;
    line-height: $line-height-extra-large-typography;
  }

  .MuiTypography-h2 {
    color: $color-text-typography;
    font-size: $heading-level2-h2-typography;
    font-weight: $text-boldest-typography;
    line-height: $line-height-large-typography;
  }

  .MuiTypography-h3 {
    color: $color-text-typography;
    font-size: $heading-level2-h3-typography;
    font-weight: $text-bolder-typography;
    line-height: $line-height-typography-32;
    margin-top: 0px;
  }

  //End Heading Level//

  //Display Level//
  .MuiTypography-h4 {
    font-size: $display-level1-h2-typography;
    font-weight: $text-bolder-typography;
    color: $color-text-typography;
  }

  .MuiTypography-h5 {
    font-size: $display-level2-h4-typography;
    font-weight: $text-bolder-typography;
    color: $color-text-typography;
  }

  //End Display Level//

  //Font weights//
  .MuiTypography-body1 {
    font-size: $font-base;
    color: $color-text-typography;
    font-weight: $text-regular;
  }

  .MuiTypography-subtitle2 {
    font-size: $font-base;
    color: $color-text-typography;
    font-weight: $text-bold;
  }

  .MuiTypography-h6 {
    font-size: $font-medium;
    color: $color-text-typography;
    font-weight: $text-bolder;
  }

  .heading-typography {
    font-size: $font-large;
    color: $color-text-typography;
    font-weight: $text-bolder-650;
  }

  //End Font weights variant//

  //font weights //
  .MuiTypography-body1 {
    // font-size: $font-base;
    color: $color-text-typography;
    // margin-bottom: $space-default;
    &.regular {
      font-weight: $text-regular;
    }
    &.bold {
      font-weight: $text-bold;
    }
    &.bolder {
      font-weight: $text-bolder;
    }
    &.boldest {
      font-weight: $text-bolder-650;
    }
  }

  //font weights//

  //Body text//
  .MuiTypography-body2 {
    font-size: $font-base;
    color: $color-text-typography;
    font-weight: $text-regular;
  }

  // End Body text//

  //span text//
  .MuiTypography-body1 > span {
    font-size: $font-base;
    color: $color-text-typography;
    font-weight: $text-regular;
  }

  // End span text//
}
