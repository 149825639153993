.icons-page {
    .icons-page_header {
        background: rgb(45, 45, 139);
        width: calc(100% - 28%);
        float: left;
        padding: 50px 14% 0;
        min-height: 100px;
        vertical-align: middle;
        margin-top: 55px;

        h1 {
            color: #fff;
            font-size: 22px;
            margin: 0;
            line-height: 50px;
        }

        small {
            font-size: 14px;
            color: #bcbcbc;
        }

        #searchInput {
            height: 32px;
            padding: 8px;
            width: 50%;
            border-radius: 10px;
            border: none;
            background: rgba(256, 256, 256, 0.8);
        }
    }

    ul.icons-library-list {
        width: 97%;
        list-style-type: none;

        li {
            width: 13%;
            float: left;
            height: 80px;
            border: 1px solid #c9c9c9;
            padding: 30px 10px 10px;
            margin: 10px;
            border-radius: 3px;
            position: relative;

            em {
                font-size: 28px;
                display: block;
                text-align: center;
                height: 60%;
            }

            span {
                text-align: center;
                display: block;
                font-size: 12px;

                &.code-copied {
                    background: green;
                    color: #fff;
                    font-size: 12px;
                    position: absolute;
                    top: 0;
                    padding: 2px 12px;
                    border-radius: 3px;
                    left: 35%;
                    box-shadow: 0px 0px 10px 1px #bababa;
                }
            }

            button {
                background: #f1f1f9;
                position: absolute;
                right: 0;
                border: 1px solid #d0d0e9;
                padding: 4px;
                border-radius: 3px;
                top: 0;
                margin: 10px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background: #e3e3f1;
                }

                >em {
                    font-size: 16px;
                    color: #2d2d8b;
                }

            }
        }


    }

}

.tooltip {
    position: relative;

    &::after {
        font-size: 0.75rem;
        font-weight: normal;
        content: attr(data-tooltip);
        background-color: #000000cc;
        border-radius: 10px;
        color: #ffffff;
        display: block;
        padding: 6px 12px;
        position: absolute;
        text-align: center;
        z-index: 999;
        transition: all 200ms;
        white-space: nowrap;
    }

    &#{&}-xl::after {
        width: 400px;
    }

    &#{&}-lg::after {
        width: 255px;
    }

    &#{&}-md::after {
        width: 127px;
    }

    &#{&}-sm::after {
        width: auto;
    }

    &.top::after {
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-100% - 10px)) scale(0);
    }

    &.right::after {
        top: 50%;
        right: 0;
        transform: translate(calc(100% + 10px), -50%) scale(0);
    }

    &.bottom::after {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, calc(100% + 10px)) scale(0);
    }

    &.left::after {
        top: 50%;
        left: 0;
        transform: translate(calc(-100% - 10px), -50%) scale(0);
    }

    &:hover::after {
        transform: scale(1);
    }

    &:hover {
        &.top::after {
            transform: translate(-50%, calc(-100% - 10px));
        }

        &.right::after {
            transform: translate(calc(100% + 10px), -50%);
        }

        &.bottom::after {
            transform: translate(-50%, calc(100% + 10px));
        }

        &.left::after {
            transform: translate(calc(-100% - 10px), -50%);
        }
    }
}

.text-white {
    color: #fff;
}