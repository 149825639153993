// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap')
//$font-family: 'Open Sans', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
// @import url('https://api.fontshare.com/v2/css?f[]=work-sans@200,300,500,600,700,400&display=swap');
// @import url('https://api.fontshare.com/v2/css?f[]=supreme@800,400,401,100,300,301,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$font-family: "Nunito Sans", "Open Sans", "IBM Plex Sans", "Raleway", "Urbanist", "Montserrat", sans-serif;

* {
    font-family: $font-family;
}


// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null !default;
$font-size-base: 0.8rem !default; // Assumes the browser default, typically `14px`
$font-size-sm: $font-size-base * .875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-bolder: 600 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: 2rem !default;
$h2-font-size: 1.5rem !default;
$h3-font-size: 1.25rem !default;
$h4-font-size: 1.15rem !default;
$h5-font-size: 1.1rem !default;
$h6-font-size: 1rem !default;

// Container Variables
//
// Define container widths, these will be applied to the 
// .container element. Values must be ascending.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);

// grid variables
$grid__cols: 12;
$grid-gap: 0.8rem;
$grid-columnsset: (
    //   '': 0,
    -xs: 360px,
    -sm: 480px,
    -md: 720px,
    -lg: 960px,
    -xl: 1160px,

);
//Color Variables
// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start gray-colors-map
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900) !default;
// scss-docs-end gray-colors-map


// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #D14343 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $black !default;
$transparent: none !default;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "white": $white,
    "dark": $dark,
    ) !default;


$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

//Spacings
$base-padding: 0.75rem;
$base-margin: 0.75rem;
// Medium Button Paddings
$btn-padding-x: 0.3rem;
$btn-padding-y: 0.7rem;
// Large Button Paddings
$btn-padding-x-lg: calc($btn-padding-x * 2
);
$btn-padding-y-lg: calc($btn-padding-y * 2);
// Small Button Paddings
$btn-padding-x-sm: calc($btn-padding-x / 2);
$btn-padding-y-sm: calc($btn-padding-y / 2);
// X-Small Button Paddings
$btn-padding-x-xs: calc($btn-padding-x / 3);
$btn-padding-y-xs: calc($btn-padding-y / 3);



$btn-font: $font-size-sm;
//borders
$base-border-thickness: 1px;
$base-border-radius: 6px;

$base-border-radius-xs: calc($base-border-radius / 2);
$base-border-radius-sm: calc($base-border-radius / 1);
$base-border-radius-lg: calc($base-border-radius * 2);
$base-border-radius-xl: calc($base-border-radius * 4);


//Layout
$imp: !important;
$header-height: 46px;

//tooltip's default height
$tooltipHeight: 22px;

//tooltip's default arrow size
$arrowSize: 6px;

//tooltip's default box color
$tooltipColor: #323232;

//tooltip's default text color
$tooltipTextColor: #ecf0f1;