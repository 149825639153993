@font-face {
  font-family: "freyaicons";
  src: url("assets/fonts/iconlibrary.eot?iz8a8p");
  src: url("assets/fonts/iconlibrary.eot?iz8a8p#iefix") format("embedded-opentype"),
    url("assets/fonts/iconlibrary.ttf?iz8a8p") format("truetype"),
    url("assets/fonts/iconlibrary.woff?iz8a8p") format("woff"),
    url("assets/fonts/iconlibrary.svg?iz8a8p#iconlibrary") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@import 'assets/scss/abstracts/variables.scss';
@import 'assets/scss/layout/header.scss';
@import 'assets/scss/layout/sidebar.scss';
@import 'assets/scss/layout/content.scss';
@import 'assets/scss/layout/grid';

//Pages
@import 'assets/scss/pages/iconspage';